import React from 'react'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Divider } from '@mui/material'
import { useBreakPoints, useLOCValues, usePlayerSeasonBoxscoreStats } from '../../../lib/hooks'
import FormattedText from '../FormattedText'
import PlayerList from './PlayerList'
import PlayerGameSeasonComboBoxscore from '@/components/form/PlayerGameSeasonComboBoxscore'

type ScoutingContentProps = {
    athleticism: number | null
    bbiq: number | null
    shooter: number | null
    shotCreator: number | null
    quickDecisions: number | null
    scorer: number | null
    finisher: number | null
    playMaker: number | null
    rimProtector: number | null
    teamDefender: number | null
    individualDefense: number | null
    pnrDefender: number | null
    rebounder: number | null
    competitiveness: number | null
    locNow: number | null
    locBullseye: number | null
    locLow: number | null
    locHigh: number | null
    offense: string | null
    defense: string | null
    physical: string | null
    playerLists: DTO.PlayerList[]
    boxscores: DTO.GameBoxscore[] | undefined
    offensePrimary: DTO.PlayerPrimaryPosition | null
    defensePrimary: DTO.PlayerPrimaryPosition | null
    offensePG: Enum.PositionVersatility | null
    offenseSG: Enum.PositionVersatility | null
    offenseSF: Enum.PositionVersatility | null
    offensePF: Enum.PositionVersatility | null
    offenseC: Enum.PositionVersatility | null
    defensePG: Enum.PositionVersatility | null
    defenseSG: Enum.PositionVersatility | null
    defenseSF: Enum.PositionVersatility | null
    defensePF: Enum.PositionVersatility | null
    defenseC: Enum.PositionVersatility | null
    isBoxScoreLoading?: boolean
    seasonStats: DTO.SeasonStats[] | undefined
    isSeasonStatsLoading: boolean
}

type LocProps = {
    label: string
    value: string | undefined
}

const skillLabels = {
    athleticism: 'Athleticism',
    shotCreator: 'Shot Creator',
    finisher: 'Finisher',
    teamDefender: 'Team Def.',
    bbiq: 'BBIQ',
    quickDecisions: 'Quick Dec.',
    playMaker: 'Play Maker',
    rimProtector: 'Rim Protector',
    pnrDefender: 'PnR Def.',
    scorer: 'Scorer',
    shooter: 'Shooter',
    individualDefense: 'Individual Def',
    rebounder: 'Rebounder',
    competitiveness: 'Competitiveness',
}

const Locs = ({ label, value }: LocProps) => (
    <Chip
        sx={{ paddingY: 1.5, paddingX: 0.5 }}
        size="small"
        label={
            <Stack direction="row" alignItems="baseline" spacing={1}>
                <Typography color="text.secondary" variant="overline" fontWeight="medium">
                    {label}
                </Typography>
                <Typography variant="body2" fontWeight="medium">
                    {value}
                </Typography>
            </Stack>
        }
        variant="outlined"
    />
)

type SkillProps = {
    label: string
    value: number
}

const Skill = ({ label, value }: SkillProps) => (
    <Chip
        sx={{ paddingY: 1.5, paddingX: 0.5 }}
        size="small"
        label={
            <Stack direction="row" alignItems="baseline" spacing={1}>
                <Typography color="text.secondary" variant="overline" fontWeight="medium">
                    {label}
                </Typography>
                <Typography variant="body2" fontWeight="medium">
                    {value}
                </Typography>
            </Stack>
        }
    />
)

type PrimaryPositionProps = {
    value: DTO.PlayerPrimaryPosition
}

const PrimaryPosition = ({ value }: PrimaryPositionProps) => (
    <Chip
        sx={{ paddingY: 1.5, paddingX: 0.5, marginBottom: { xs: 1, sm: undefined } }}
        size="small"
        label={
            <Stack direction="row" alignItems="baseline" spacing={1}>
                <Typography color="text.secondary" variant="overline" fontWeight="medium">
                    Primary
                </Typography>
                <Typography variant="body2" fontWeight="medium">
                    {value}
                </Typography>
            </Stack>
        }
        variant="outlined"
    />
)

type PositionVersatilityOption = { label: DTO.PlayerPrimaryPosition; value: Enum.PositionVersatility | null }
type PositionVersatilityProps = {
    positionVersatilityOptions: PositionVersatilityOption[]
}

const positionVersatilityLabels = {
    NOT_GOOD_ENOUGH: 'Not Good Enough',
    BELOW_AVERAGE: 'Below Avg',
    AVERAGE: 'Avg',
    ABOVE_AVERAGE: 'Above Avg',
} as const

const PositionVersatility = ({ positionVersatilityOptions }: PositionVersatilityProps) => (
    <Chip
        sx={{
            paddingY: 1.5,
            paddingX: 0.5,
            '& .MuiChip-label': {
                display: { xs: 'block', sm: 'flex' },
                whiteSpace: { xs: 'normal', sm: 'nowrap' },
                gap: 1,
            },
            height: { xs: 'auto', sm: '24px' },
        }}
        size="small"
        label={positionVersatilityOptions.map((pv, i) => (
            <Stack direction="row" alignItems="baseline" spacing={1}>
                <Typography color="text.secondary" variant="overline" fontWeight="medium">
                    {pv.label}
                </Typography>
                <Typography variant="body2" fontWeight="medium">
                    {pv.value ? positionVersatilityLabels[pv.value] : <span>&#8212;</span>}
                </Typography>
                {i !== positionVersatilityOptions.length - 1 && (
                    <Divider
                        orientation="vertical"
                        sx={{ display: { xs: 'none', sm: 'flex' }, margin: '4px 0 4px 8px!important' }}
                        flexItem
                    />
                )}
            </Stack>
        ))}
        variant="outlined"
    />
)

const offensePositionVersatilityOptions = (
    pv: Record<string, Enum.PositionVersatility | null>
): PositionVersatilityOption[] => [
    { label: 'PG', value: pv.offensePG },
    { label: 'SG', value: pv.offenseSG },
    { label: 'SF', value: pv.offenseSF },
    { label: 'PF', value: pv.offensePF },
    { label: 'C', value: pv.offenseC },
]
const defensePositionVersatilityOptions = (
    pv: Record<string, Enum.PositionVersatility | null>
): PositionVersatilityOption[] => [
    { label: 'PG', value: pv.defensePG },
    { label: 'SG', value: pv.defenseSG },
    { label: 'SF', value: pv.defenseSF },
    { label: 'PF', value: pv.defensePF },
    { label: 'C', value: pv.defenseC },
]

const ScoutingContent = ({
    athleticism,
    bbiq,
    shooter,
    shotCreator,
    quickDecisions,
    scorer,
    finisher,
    playMaker,
    rimProtector,
    teamDefender,
    individualDefense,
    pnrDefender,
    rebounder,
    competitiveness,
    offense,
    defense,
    physical,
    locNow,
    locBullseye,
    locHigh,
    locLow,
    playerLists,
    boxscores,
    isBoxScoreLoading,
    offensePrimary,
    defensePrimary,
    offensePG,
    offenseSG,
    offenseSF,
    offensePF,
    offenseC,
    defensePG,
    defenseSG,
    defenseSF,
    defensePF,
    defenseC,
    seasonStats,
    isSeasonStatsLoading,
}: ScoutingContentProps): JSX.Element => {
    const { isMobile, isTablet } = useBreakPoints()
    const { data: locValues } = useLOCValues()
    const [skillsVisible, setSkillsVisible] = React.useState(false)
    const [positionsVisible, setPositionsVisible] = React.useState(false)

    const noSkills =
        !shooter &&
        !shotCreator &&
        !scorer &&
        !quickDecisions &&
        !finisher &&
        !playMaker &&
        !rimProtector &&
        !teamDefender &&
        !individualDefense &&
        !pnrDefender &&
        !athleticism &&
        !bbiq &&
        !rebounder &&
        !competitiveness &&
        shooter !== 0 &&
        shotCreator !== 0 &&
        scorer !== 0 &&
        quickDecisions !== 0 &&
        finisher !== 0 &&
        playMaker !== 0 &&
        rimProtector !== 0 &&
        teamDefender !== 0 &&
        individualDefense !== 0 &&
        pnrDefender !== 0 &&
        athleticism !== 0 &&
        bbiq !== 0 &&
        rebounder !== 0 &&
        competitiveness !== 0

    const hasOffensiveSkills =
        shooter ||
        shotCreator ||
        scorer ||
        quickDecisions ||
        finisher ||
        playMaker ||
        shooter === 0 ||
        shotCreator === 0 ||
        scorer === 0 ||
        quickDecisions === 0 ||
        finisher === 0 ||
        playMaker === 0

    const hasDefensiveSkills =
        rimProtector ||
        teamDefender ||
        individualDefense ||
        pnrDefender ||
        rimProtector === 0 ||
        teamDefender === 0 ||
        individualDefense === 0 ||
        pnrDefender === 0

    const hasGeneralSkills =
        athleticism ||
        bbiq ||
        rebounder ||
        competitiveness ||
        athleticism === 0 ||
        bbiq === 0 ||
        rebounder === 0 ||
        competitiveness === 0

    const noLocs =
        !locBullseye &&
        !locHigh &&
        !locNow &&
        !locLow &&
        locBullseye !== 0 &&
        locHigh !== 0 &&
        locNow !== 0 &&
        locLow !== 0

    const hasOffensePositionalVersatility = offensePG || offenseSG || offenseSF || offensePF || offenseC
    const hasDefensePositionalVersatility = defensePG || defenseSG || defenseSF || defensePF || defenseC

    const noPositions =
        !offensePrimary && !defensePrimary && !hasOffensePositionalVersatility && !hasDefensePositionalVersatility

    const playerSeasonStats = usePlayerSeasonBoxscoreStats(
        seasonStats,
        isSeasonStatsLoading,
        (s) => !!s.teamId && !!s.season && !!(s.league === 'NBA' || s.league === 'G-League' || s.league === 'College')
    )

    return (
        <>
            <Stack marginBottom={1}>
                {offense && <FormattedText label="Offense" text={offense} />}
                {defense && <FormattedText label="Defense" text={defense} />}
                {physical && <FormattedText label="Physical" text={physical} />}
            </Stack>
            {!noLocs && (
                <Stack marginBottom={2}>
                    <Typography variant="subtitle2" fontSize="16px" gutterBottom>
                        LOCs
                    </Typography>

                    <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1 }}>
                        {(locNow === 0 || locNow) && (
                            <Locs label="Now" value={locValues?.find((v) => v.value === locNow)?.label} />
                        )}
                        {(locBullseye === 0 || locBullseye) && (
                            <Locs label="Bull" value={locValues?.find((v) => v.value === locBullseye)?.label} />
                        )}
                        {(locLow === 0 || locLow) && (
                            <Locs label="Low" value={locValues?.find((v) => v.value === locLow)?.label} />
                        )}
                        {(locHigh === 0 || locHigh) && (
                            <Locs label="High" value={locValues?.find((v) => v.value === locHigh)?.label} />
                        )}
                    </Stack>
                </Stack>
            )}
            {!!playerLists.length && (isMobile || isTablet) && (
                <Stack marginBottom={2}>
                    <Typography variant="subtitle2" fontSize="16px" gutterBottom>
                        Player Lists
                    </Typography>
                    <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1 }}>
                        {playerLists.map(({ name }) => (
                            <PlayerList key={name} name={name} />
                        ))}
                    </Stack>
                </Stack>
            )}
            {!noSkills && (
                <Box sx={{ marginBottom: 2 }}>
                    <Stack direction="row" alignItems="baseline" gap={1}>
                        <Typography variant="subtitle2" fontSize="16px">
                            Skills
                        </Typography>
                        <Button
                            onClick={() => setSkillsVisible(!skillsVisible)}
                            variant="text"
                            color="info"
                            sx={{ fontSize: '12px', padding: '2px' }}
                        >
                            {skillsVisible ? 'Hide' : 'Show'}
                        </Button>
                    </Stack>
                    <Box sx={{ display: skillsVisible ? 'block' : 'none' }}>
                        {hasOffensiveSkills && (
                            <Typography variant="subtitle2" fontSize="14px" marginLeft="2px" marginTop={1} gutterBottom>
                                Offense
                            </Typography>
                        )}
                        <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1 }} marginBottom={2}>
                            {(scorer === 0 || scorer) && <Skill label={skillLabels.scorer} value={scorer} />}
                            {(shooter === 0 || shooter) && <Skill label={skillLabels.shooter} value={shooter} />}
                            {(finisher === 0 || finisher) && <Skill label={skillLabels.finisher} value={finisher} />}
                            {(shotCreator === 0 || shotCreator) && (
                                <Skill label={skillLabels.shotCreator} value={shotCreator} />
                            )}
                            {(playMaker === 0 || playMaker) && (
                                <Skill label={skillLabels.playMaker} value={playMaker} />
                            )}
                            {(quickDecisions === 0 || quickDecisions) && (
                                <Skill label={skillLabels.quickDecisions} value={quickDecisions} />
                            )}
                        </Stack>
                        {hasDefensiveSkills && (
                            <Typography variant="subtitle2" fontSize="14px" marginLeft="2px" gutterBottom>
                                Defense
                            </Typography>
                        )}
                        <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1 }} marginBottom={2}>
                            {(pnrDefender === 0 || pnrDefender) && (
                                <Skill label={skillLabels.pnrDefender} value={pnrDefender} />
                            )}
                            {(rimProtector === 0 || rimProtector) && (
                                <Skill label={skillLabels.rimProtector} value={rimProtector} />
                            )}
                            {(teamDefender === 0 || teamDefender) && (
                                <Skill label={skillLabels.teamDefender} value={teamDefender} />
                            )}
                            {(individualDefense === 0 || individualDefense) && (
                                <Skill label={skillLabels.individualDefense} value={individualDefense} />
                            )}
                        </Stack>
                        {hasGeneralSkills && (
                            <Typography variant="subtitle2" fontSize="14px" marginLeft="2px" gutterBottom>
                                General
                            </Typography>
                        )}
                        <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1, marginBottom: 2 }}>
                            {(athleticism === 0 || athleticism) && (
                                <Skill label={skillLabels.athleticism} value={athleticism} />
                            )}
                            {(bbiq === 0 || bbiq) && <Skill label={skillLabels.bbiq} value={bbiq} />}
                            {(rebounder === 0 || rebounder) && (
                                <Skill label={skillLabels.rebounder} value={rebounder} />
                            )}
                            {(competitiveness === 0 || competitiveness) && (
                                <Skill label={skillLabels.competitiveness} value={competitiveness} />
                            )}
                        </Stack>
                    </Box>
                </Box>
            )}
            {!noPositions && (
                <Box>
                    <Stack direction="row" alignItems="baseline" gap={1}>
                        <Typography variant="subtitle2" fontSize="16px">
                            Positions
                        </Typography>
                        <Button
                            onClick={() => setPositionsVisible(!positionsVisible)}
                            variant="text"
                            color="info"
                            sx={{ fontSize: '12px', padding: '2px' }}
                        >
                            {positionsVisible ? 'Hide' : 'Show'}
                        </Button>
                    </Stack>
                    {positionsVisible && (
                        <>
                            {(offensePrimary || hasOffensePositionalVersatility) && (
                                <>
                                    <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1 }}>
                                        <Typography
                                            variant="subtitle2"
                                            fontSize="14px"
                                            marginLeft="2px"
                                            marginTop={1}
                                            gutterBottom
                                        >
                                            Offense
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        direction="row"
                                        sx={{ flexWrap: 'wrap', gap: 1, display: { xs: 'block', sm: 'flex' } }}
                                    >
                                        {offensePrimary && <PrimaryPosition value={offensePrimary} />}
                                        {hasOffensePositionalVersatility && (
                                            <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1 }}>
                                                <PositionVersatility
                                                    positionVersatilityOptions={offensePositionVersatilityOptions({
                                                        offensePG,
                                                        offenseSG,
                                                        offenseSF,
                                                        offensePF,
                                                        offenseC,
                                                    })}
                                                />
                                            </Stack>
                                        )}
                                    </Stack>
                                </>
                            )}
                            {(defensePrimary || hasDefensePositionalVersatility) && (
                                <>
                                    <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1 }}>
                                        <Typography
                                            variant="subtitle2"
                                            fontSize="14px"
                                            marginLeft="2px"
                                            marginTop={1}
                                            gutterBottom
                                        >
                                            Defense
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        direction="row"
                                        sx={{
                                            flexWrap: 'wrap',
                                            gap: 1,
                                            display: { xs: 'block', sm: 'flex' },
                                        }}
                                    >
                                        {defensePrimary && <PrimaryPosition value={defensePrimary} />}
                                        {hasDefensePositionalVersatility && (
                                            <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1 }}>
                                                <PositionVersatility
                                                    positionVersatilityOptions={defensePositionVersatilityOptions({
                                                        defensePG,
                                                        defenseSG,
                                                        defenseSF,
                                                        defensePF,
                                                        defenseC,
                                                    })}
                                                />
                                            </Stack>
                                        )}
                                    </Stack>
                                </>
                            )}
                        </>
                    )}
                </Box>
            )}
            {(isBoxScoreLoading || isSeasonStatsLoading || !!boxscores?.length || !!playerSeasonStats.length) && (
                <Box marginTop={2}>
                    <Typography variant="subtitle2" fontSize="16px">
                        Box Score
                    </Typography>
                    <PlayerGameSeasonComboBoxscore
                        boxscores={boxscores || []}
                        seasonStats={playerSeasonStats}
                        isBoxScoreLoading={isBoxScoreLoading || isSeasonStatsLoading}
                    />
                </Box>
            )}
        </>
    )
}

export default ScoutingContent
