import type TableConfig from '@/lib/types/tableConfigTypes'
import type { CellColor } from '@/lib/utils/colors'
import React from 'react'
import Table from '@/components/Table'
import { formatHeight } from '@/lib/utils/formatters'
import { formatNumber, isNumber } from '@/lib/utils/math'
import { useMeasurementPercentiles } from '@/lib/hooks/useMeasurements'
import { getMeasurementThresholdColor } from '@/lib/utils/colors'

type MeasurementParticipants = Omit<DTO.MeasurementsTable, 'playerId' | 'secondaryPosition' | 'measurementSource' | 'annotations'>

type ParticipantMeasurementsProps = {
    measurements: MeasurementParticipants | null
    draftYear: number
    secondaryPosition: DTO.PlayerSecondaryPosition
}

const columns: {
    title: keyof MeasurementParticipants
    label: string
    format: (val: number | null) => string | null
}[] = [
    {
        title: 'heightInShoes',
        label: 'HT (Shoes)',
        format: (val: number | null) => (val ? formatHeight(val, 1) : '--'),
    },
    { title: 'wingspan', label: 'Wing', format: (val: number | null) => (val ? formatHeight(val, 1) : '--') },
    {
        title: 'wingspanMinusHeight',
        label: 'Diff',
        format: (val: number | null) => (val ? `${val > 0 ? '+' : ''}${formatNumber(val, 1) || '--'}` : '--'),
    },
    { title: 'weight', label: 'WT', format: (val: number | null) => (val ? formatNumber(val, 0) : '--') },
    { title: 'standingReach', label: 'Reach', format: (val: number | null) => (val ? formatHeight(val, 0) : '--') },
    { title: 'run34', label: 'Sprint', format: (val: number | null) => (val ? formatNumber(val, 1) : '--') },
    { title: 'laneAgility', label: 'Lane', format: (val: number | null) => (val ? formatNumber(val, 1) : '--') },
    {
        title: 'verticalJumpMax',
        label: 'Max Vert',
        format: (val: number | null) => (val ? formatNumber(val, 1) : '--'),
    },
    {
        title: 'verticalJumpNoStep',
        label: 'NS Vert',
        format: (val: number | null) => (val ? formatNumber(val, 1) : '--'),
    },
]

const tableConfig = (percentiles: DTO.MeasurementPercentileMap, secondaryPosition: DTO.PlayerSecondaryPosition) => {
    const fields: TableConfig<MeasurementParticipants>['fields'] = columns.map((d) => ({
        key: d.title,
        label: d.label,
        select: (x) => {
            const val = x[d.title]
            if (val) {
                return isNumber(val) ? d.format(val) : x[d.title]
            }
            return '--'
        },
        skeletonStyle: { xs: 30 },
        headCellFormat: {
            whiteSpace: 'nowrap',
        },
        format: (x) => {
            const val = x[d.title]
            const measurementThresholdColor: CellColor | undefined =
                d.title === 'measurementDate' || !isNumber(val)
                    ? undefined
                    : getMeasurementThresholdColor(d.title, val, 'College', secondaryPosition, percentiles)

            return {
                minWidth: '60px !important',
                maxWidth: '60px !important',
                ...measurementThresholdColor,
            }
        },
        numeric: false,
    }))

    return {
        loadingSkeleton: {
            numOfRows: 1,
            height: 100,
        },
        fields,
    }
}

const ParticipantMeasurments = ({
    measurements,
    draftYear,
    secondaryPosition,
}: ParticipantMeasurementsProps): JSX.Element => {
    const { data: percentiles, isLoading: isLoadingPercentiles } = useMeasurementPercentiles({
        secondaryPosition,
        leagues: ['College'],
        season: draftYear,
    })

    return (
        <Table
            config={tableConfig(percentiles || {}, secondaryPosition)}
            rows={measurements ? [measurements] : []}
            getRowKey={(d) => d.measurementDate || ''}
            noResultsMessage="No Measurements Found"
            isLoading={isLoadingPercentiles}
            formatTableContainer={{
                // @ts-expect-error - Valid Selector
                th: {
                    paddingX: 1,
                    paddingY: 0.5,
                },

                td: {
                    padding: 1,
                    paddingY: 0.5,
                },
                h6: {
                    fontSize: '16px',
                },
            }}
        />
    )
}

export default ParticipantMeasurments
